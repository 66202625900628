import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Carousel from 'nuka-carousel'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TopBanner from '../components/TopBanner'
import SC from '../components/SoundCloudWidget'
import staticGif from '../img/background-particles.gif'

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: default;
  position: relative;
  background: black;
  z-index: 1;
`

const background = keyframes`
0% { background-color: rgba(245, 222, 179, 0);}
50% { background-color: rgba(245, 222, 179, 0.15);}
100% { background-color: rgba(245, 222, 179, 0);}
`

const skew = keyframes`
  1% { transform: skew(0deg);}
  1.25% { transform: skew(15deg);}
  1.5% { transform: skew(0deg);}
  19.75% { transform: skew(0deg); opacity: 1;}
  20% { transform: skew(100deg); opacity: 0.75;}
  20.25% { transform: skew(0deg); opacity: 1;}
`

const SoundCloudPlayer = styled.iframe.attrs(({ url, ref, onLoad }) => ({
  ref,
  onLoad,
  width: '100%',
  maxHeight: '100px',
  scrolling: 'no',
  frameBorder: 'no',
  src: `https://w.soundcloud.com/player/?url=${url}&show_artwork=false&hide_related=true&auto_play=false&color=red&&visual=false&show_user=false`,
}))`
  animation: ${background} 4s ease-in;
  width: 100%;
  max-height: 100px;
  flex-basis: 100%;
  overflow: hidden;
  mix-blend-mode: screen;
  border: 2px solid black;
  html, body {
    overflow: hidden;
  }
`

class PureSoundCloudPlayer extends React.PureComponent {
  render() {
    const { props: { url, iFrameRef, onLoad } } = this
    return (
      <SoundCloudPlayer ref={iFrameRef} url={url} onLoad={onLoad} />
    )
  }
}

const StyledCarousel = styled(Carousel)`
  height: 100px;
  opacity: 0.94;
  ul.slider-list, li.slider-slide.slide-visible, ${StyledSection} {
    :focus {
      outline: none;
    }
  }
`

const SongNav = styled.div`
  display: flex;
  width: 100%;
`

const NavButton = styled.button`
  font-family: Montserrat, sans-serif;
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 1px solid #222;
  height: 75px;
  background-color: #222;
  font-size: calc(3vw + 8px);
  letter-spacing: 10px;
  text-transform: uppercase;
  box-shadow: inset 0 0 0 1px #affdd3;
  color: #affdd3;
  transition: box-shadow 250ms ease;
  :hover {
    cursor: pointer;
    transition: box-shadow 250ms ease;
    background-color: #2222;
    box-shadow: inset 0 0 0 3px #affdd3;
  }
  :disabled {
    color: #5a5757;
    cursor: not-allowed;
  }
`

const TrackInfoButton = styled(NavButton)`
  font-size: calc(1vw + 8px);
  color: #e6c992;
  letter-spacing: 3px;
  padding: 0 5px;
  flex: 1;
`

const SongDescription = styled(SongNav)`
  flex-direction: column;
  justify-content: center;
  background: #222;
  min-height: 75px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #222;
  min-height: 100px;
  opacity: ${({ showTrackInfo }) => showTrackInfo ? '1' : '0'};
  z-index: ${({ showTrackInfo }) => showTrackInfo ? '1' : '-1'};
  transition: opacity 200ms ease-out;
`

const TrackName = styled.h3`
  font-size: calc(3vw + 8px);
  color: #affdd3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
`

const Name = styled.span`
  color: lightpink;
  margin-left: calc(1.4vw + 5px);
`

const Description = styled.p`
  font-size: calc(1vw + 12px);
  color: lightblue;
  padding: calc(1vh + 5px) 10px;
`

const Track = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex-direction: column;
`

const TrackContainer = styled.section`
	height: 100%;
  min-height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  z-index: 0;
`

const Visuals = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    ::before {
      content: "";
      background: url(${staticGif});
      background-repeat: round;
      opacity: ${({ isPlaying }) => isPlaying ? '0.5' : '0.04'};
      transition: opacity 0.5s ease;
      top: 0;
      left: 0;
      bottom: 100px;
      right: 0;
      position: absolute;
      z-index: -1;
    }
`

const Play = styled.span`
  animation: ${skew} ${Math.random() * 5 + 2}s infinite;
  font-size: 4vw;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1vw;
  color: transparent;
  mix-blend-mode: screen;
  text-shadow: 0 0 0.1vw #FFFFFF;
`

export class SoundsPageTemplate extends React.Component {

  constructor(props) {
    super(props)
    this.soundcloudRefs = props.soundcloudMixes.map(() => React.createRef())
    this.state = {
      isLocked: false,
      currentTrack: 0,
      currentTrackPlaying: null,
      loadedTracks: [],
      isPlaying: false,
      showTrackInfo: false,
      currentVideoTextIdx: 0,
    }
  }

  currentTrackIsLoaded = () =>
    !!this.state.loadedTracks.includes(this.state.currentTrack)

  togglePlay = idx => {
    if (!this.currentTrackIsLoaded()) return
    const id = this.state.currentTrack
    const widget = SC.Widget(this.soundcloudRefs[id].current)
    return widget.play()
  }

  playVideo = (idx, isWidget) => {
    const { videoText } = this.props
    if (this.videoInterval) clearInterval(this.videoInterval)
    this.setState({ isPlaying: true, currentTrackPlaying: idx })
    this.videoInterval = setInterval(() =>
      this.setState(({ currentVideoTextIdx }) =>
        ({ currentVideoTextIdx: (currentVideoTextIdx + 1) % videoText.length })
      ), 2500)
  }

  pauseVideo = (idx, isWidget) => {
    if (isWidget && idx !== this.state.currentTrackPlaying) return
    clearInterval(this.videoInterval)
    this.setState({
      isPlaying: false,
      currentTrackPlaying: null,
    })
  }

  toggleTrackInfo = () =>
    this.setState(({ showTrackInfo }) => ({ showTrackInfo: !showTrackInfo }))

  addLoadedTrack = idx => () => {
    if (!window) return
    const widget = SC.Widget(this.soundcloudRefs[idx].current)
    widget.bind(SC.Widget.Events.PAUSE, () => this.pauseVideo(idx, true))
    widget.bind(SC.Widget.Events.PLAY, () => this.playVideo(idx, true))
    this.setState(({ loadedTracks }) =>
      ({ loadedTracks: [...loadedTracks, idx] }))
  }

  previousTrack = () => {
    const id = this.state.currentTrack
    const widget = SC.Widget(this.soundcloudRefs[id].current)
    widget.pause()
    this.setState(({ currentTrack }) =>
      ({ currentTrack: currentTrack - 1, isLocked: true }))
  }

  nextTrack = () => {
    const id = this.state.currentTrack
    const widget = SC.Widget(this.soundcloudRefs[id].current)
    widget.pause()
    this.setState(({ currentTrack }) =>
      ({ currentTrack: currentTrack + 1, isLocked: true }))
  }

  render() {
    const { previousTrack, nextTrack, props: { videoText, soundcloudMixes }, state: { showTrackInfo, currentTrack, isLocked, isPlaying } } = this
    return (
      <Layout hasPadding>
        <TopBanner title="sounds" />
        <TrackContainer>
          <Track>
            <Visuals isPlaying={isPlaying}>
              <Play>{isPlaying ? videoText[this.state.currentVideoTextIdx].text : 'paused'}</Play>
            </Visuals>
            <StyledCarousel
              slideIndex={currentTrack}
              renderCenterRightControls={() => {}}
              renderCenterLeftControls={() => {}}
              renderBottomCenterControls={() => {}}
              transitionMode="fade"
              afterSlide={() => this.setState({ isLocked: false })}
            >
              {soundcloudMixes.map(({ url }, idx) => (
                <StyledSection key={url}>
                  <PureSoundCloudPlayer
                    iFrameRef={this.soundcloudRefs[idx]}
                    key={url}
                    url={url}
                    onLoad={this.addLoadedTrack(idx)}
                  />
                </StyledSection>
              ))}
            </StyledCarousel>
            <SongDescription showTrackInfo={showTrackInfo}>
              <TrackName><i>TRACK: </i><Name>{soundcloudMixes[currentTrack] && soundcloudMixes[currentTrack].name}</Name></TrackName>
              <Description>{soundcloudMixes[currentTrack] && soundcloudMixes[currentTrack].description}</Description>
            </SongDescription>
          </Track>
          <SongNav>
            <NavButton
              disabled={isLocked || currentTrack === 0}
              onClick={previousTrack}
            >
              Prev
            </NavButton>
            <TrackInfoButton
              onClick={this.toggleTrackInfo}
            >
              {showTrackInfo ? 'Hide Track Info' : 'Show Track Info'}
            </TrackInfoButton>
            <NavButton
              disabled={isLocked || currentTrack === soundcloudMixes.length - 1}
              onClick={nextTrack}
            >
              Next
            </NavButton>
          </SongNav>
        </TrackContainer>
      </Layout>
    )
  }
}
SoundsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  soundcloudMixes: PropTypes.array.isRequired,
  videoText: PropTypes.array.isRequired,
}

const SoundsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <SoundsPageTemplate
      soundcloudMixes={post.frontmatter.soundcloudMixes}
      title={post.frontmatter.title}
      videoText={post.frontmatter.videoText}
    />
  )
}

SoundsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SoundsPage

export const soundsPageQuery = graphql`
  query SoundsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        videoText {
          text
        }
        soundcloudMixes {
          url
          name
          description
        }
      }
    }
  }
`
